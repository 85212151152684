<template>
  <div :class="wrapperClass">
    <div
      class="container-wrapper w-100 bg-gray-3 py-4 px-md-5 px-3 rounded d-flex align-items-center justify-content-between"
    >
      <div class="title-wrapper d-flex align-items-center">
        <i class="fa-2x mr-4 color-primary-1" :class="iconClass" />
        <span class="ml-md-3 font-weight-bold font-18 color-black">
          {{ title }}
        </span>
      </div>
      <span class="font-18 color-secondary-14 d-none d-md-block">
        {{ typeName }}
      </span>
      <i
        class="cursor-pointer fa-2x far"
        :class="{
          'fa-angle-right': !isOpen,
          'fa-angle-down color-primary-1': isOpen,
        }"
        @click="open"
      />
    </div>
    <div
      v-if="isOpen"
      class="bg-gray-3 border-radius--light p-3 mt-1"
      :class="containerClass"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapsibleSection',
  props: {
    wrapperClass: {
      type: [String, Object],
      default: () => '',
    },
    iconClass: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    typeName: {
      type: String,
      default: () => '',
    },
    containerClass: {
      type: [String, Object],
      default: () => '',
    },
    controlled: {
      type: Boolean,
      default: () => false,
    },
    opened: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    isOpen: false,
  }),
  watch: {
    opened: {
      immediate: true,
      handler(value) {
        this.isOpen = value;
      },
    },
  },
  methods: {
    open() {
      if (!this.controlled) {
        this.isOpen = !this.isOpen;
      } else {
        this.$emit('open');
      }
    },
  },
};
</script>
