<template>
  <div>
    <page-header
      :actions="pageHeaderActionsConfig"
      :page="headerConfig"
      rigth-side-class="col-12 col-md-4"
    />

    <icon-view
      class="icon-view__extended--orange"
      icon="fa fa-file-contract color-white"
      icon-background="#FF7058"
      title="Información de Solicitud"
    />

    <board :details="detailsBoard" />

    <student-location-board
      :items="mappedStudentLocationData(model.studentLocation)"
    />
    <icon-view
      class="icon-view__extended"
      gradient
      icon="fa fa-user-edit color-orange"
      icon-background="white"
      title="Información de Servicios Añadidos"
    />

    <div
      v-for="(service, idx) in model.services"
      :key="`${service.component}-service-${idx}`"
      class="mb-3"
    >
      <collapsible-section
        :icon-class="service.icon"
        :title="service.label"
        :type-name="serviceDisciplineName(service)"
      >
        <component
          :is="service.component"
          :ref="`${service.component}-${idx}`"
          :data="service[`requestService${service.component}`]"
          is-read-only
          :service-index="idx"
        />
      </collapsible-section>
    </div>

    <icon-view
      class="icon-view__extended"
      gradient
      icon="fa fa-check color-orange"
      icon-background="white"
      title="Información de Confirmación"
    />
    <div class="mt-4">
      <section-title has-line title="Comentario" />
      <custom-text-area
        id="comment"
        v-model="model.requestInfo.additionalComments"
        :count-classes="['d-block', 'text-right']"
        disabled
        :maxlength="3000"
        name="comment"
        show-char-count
      />
    </div>
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import SectionTitle from 'SectionTitle';
import IconView from 'icon-view';
import CustomTextArea from 'CustomTextArea';
import Board from 'Board';
import {
  getRequestInfo,
  getRequestServices,
} from '@/modules/provisional-remedy/api';
import ServiceTypes, {
  ServiceTypeComponentObj,
} from '@/utils/constants/provisional-remedy/serviceTypes';
import {
  Equipment,
  Barrier,
  Evaluation,
  Assistant,
  Therapy,
  Transportation,
} from '@/views/students/provisional-remedy/components';
import CollapsibleSection from '@/views/students/provisional-remedy/components/CollapsibleSection.vue';
import StudentLocationBoard from '@/views/students/provisional-remedy/components/StudentLocationBoard.vue';
import { date } from '@/utils/filters';
import Code from '@/utils/constants/provisional-remedy/requestStatus';

export default {
  name: 'ProvisionalRemedyDetail',
  components: {
    PageHeader,
    SectionTitle,
    IconView,
    CollapsibleSection,
    Equipment,
    Barrier,
    Evaluation,
    Assistant,
    Therapy,
    Transportation,
    CustomTextArea,
    StudentLocationBoard,
    Board,
  },
  computed: {
    requestId() {
      return Number(this.$route.params.requestId) || null;
    },
    serviceDisciplineName() {
      return (service) => {
        let serviceObj = null;
        switch (service.serviceTypeId) {
          case ServiceTypes.Evaluation:
            serviceObj = service.requestServiceEvaluation;
            break;
          case ServiceTypes.Therapy:
            serviceObj = service.requestServiceTherapy;
            break;
        }
        return serviceObj?.disciplineType?.name;
      };
    },
    mappedStudentLocationData() {
      return (info) => {
        return [
          {
            label: 'Lugar:',
            data: info.locationType?.name,
          },
          {
            label: 'Región:',
            data: info.region?.name,
          },
          {
            label: 'Distrito:',
            data: info.district?.name,
          },
          {
            label: 'Municipio:',
            data: info.municipality?.name,
          },
          {
            label: 'Escuela:',
            data: info.school?.name,
          },
        ];
      };
    },
    headerConfig() {
      return {
        title: 'Remedio Provisional',
        icon: 'fas fa-file-contract',
        routes: ['Inicio', 'Remedio Provisional'],
      };
    },
    pageHeaderActionsConfig() {
      return [
        {
          text: 'Regresar',
          icon: 'fas fa-arrow-circle-left',
          variant: 'outline-success',
          click: () => this.$router.go(-1),
        },
      ];
    },
    detailsBoard() {
      const info = this.model.requestInfo;
      return [
        [
          {
            label: 'Nombre del Encargado:',
            icon: 'fas fa-address-book',
            data: info?.supervisor,
            cols: 'col-12 col-md',
          },
          {
            label: 'Número de Solicitud:',
            icon: 'fas fa-ballot-check',
            data: info?.requestNumber,
            cols: 'col-12 col-md',
          },
          {
            label: 'Estatus:',
            icon: 'fas fa-ballot-check',
            data:
              info?.statusId == Code.Closed
                ? 'Sometido'
                : info?.statusDescription,
            cols: 'col-12 col-md',
          },
          {
            label: 'Fecha de Solicitud:',
            icon: 'fas fa-calendar-edit',
            data: date(info?.date),
            cols: 'col-12 col-md',
          },
        ],
      ];
    },
  },
  data: () => ({
    model: {
      requestInfo: {},
      studentLocation: {},
      services: [],
    },
  }),
  async created() {
    await Promise.all([this.getRequestInfo(), this.getServicesData()]);
  },
  methods: {
    async getRequestInfo() {
      const { data } = await getRequestInfo(this.requestId, this.studentSieId);
      this.model = {
        ...this.model,
        studentLocation: data.studentLocation,
        requestInfo: data.request,
      };
    },
    async getServicesData() {
      const { data } = await getRequestServices(this.requestId);
      this.model.services = data
        .map((d) => ({
          ...d,
          ...this.setServiceObj(d.serviceTypeId),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    setServiceObj(serviceType) {
      let service = {};
      switch (serviceType) {
        case ServiceTypes.Assistant:
          service = ServiceTypeComponentObj.Assistant;
          break;
        case ServiceTypes.Barriers:
          service = ServiceTypeComponentObj.Barriers;
          break;
        case ServiceTypes.Equipment:
          service = ServiceTypeComponentObj.Equipment;
          break;
        case ServiceTypes.Evaluation:
          service = ServiceTypeComponentObj.Evaluation;
          break;
        case ServiceTypes.Therapy:
          service = ServiceTypeComponentObj.Therapy;
          break;
        case ServiceTypes.Transportation:
          service = ServiceTypeComponentObj.Transportation;
          break;
      }
      return service;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables/colors.scss';

::v-deep .icon-view__extended {
  margin: 2rem 0;
  .font-16 {
    font-size: 1.125rem !important;
  }
  .dot {
    height: 37px;
    width: 37px;
    .title-icon {
      font-size: 1.2rem !important;
      margin-top: 0 !important;
    }
  }
  &--orange {
    @extend .icon-view__extended;
    margin-top: 0;
    margin-bottom: 1.5rem;
    width: fit-content !important;
    border-radius: 3rem;
    padding: 0.2rem 3rem 0.2rem 0.2rem;
    background: map-get($colors, 'orange-light');
  }
}
</style>
