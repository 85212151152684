const ServiceTypes = Object.freeze({
  Evaluation: 1,
  Therapy: 2,
  Equipment: 3,
  Assistant: 4,
  Transportation: 5,
  Barriers: 6,
});

export const ServiceTypeComponentObj = {
  Assistant: {
    serviceId: ServiceTypes.Assistant,
    icon: 'fal fa-users',
    label: 'Asistente',
    component: 'Assistant',
  },
  Barriers: {
    serviceId: ServiceTypes.Barriers,
    icon: 'fal fa-user-shield',
    label: 'Barreras',
    component: 'Barrier',
  },
  Equipment: {
    serviceId: ServiceTypes.Equipment,
    icon: 'fal fa-books',
    label: 'Equipo',
    component: 'Equipment',
  },
  Evaluation: {
    serviceId: ServiceTypes.Evaluation,
    icon: 'fal fa-clipboard-list-check',
    label: 'Evaluación',
    component: 'Evaluation',
  },
  Therapy: {
    serviceId: ServiceTypes.Therapy,
    icon: 'fal fa-user-clock',
    label: 'Terapia',
    component: 'Therapy',
  },
  Transportation: {
    serviceId: ServiceTypes.Transportation,
    icon: 'fal fa-bus',
    label: 'Transportación',
    component: 'Transportation',
  },
};

export default ServiceTypes;
